<template>
    <div>
        <div class="topimage">
            <el-image style="width:100%" :src="require('@/assets/guanyu/new_banner'+(this.$i18n.locale == 'zh' ? '' : '_'+this.$i18n.locale)+'.jpg')"></el-image>
        </div>
        <div class="appmain">
            <el-row v-for="(item,index) in list" :key="index">
                <div v-if="index%2 ==0" @click="nav('news_info/'+item.news_id)">
                    <el-col :span="12" :xs="24">
                        <div class="date" style="margin-right:40px;margin-top:40px" :style="{float : (index%2 ==0) ? 'right' : 'left'}">
                            <div class="datey">{{item.y}}</div>
                            <div class="datem">{{item.m}}</div>
                            <div class="dated">{{item.d}}</div>
                            <div class="dateth">th</div>
                        </div>
                        <div style="margin-right:40px">
                            <div class="ftitle" :style="{float : (index%2 ==0) ? 'right' : 'left',textAlign:(index%2 ==0) ? 'right' : 'left'}">{{item.news_title}}</div>
                            <div class="fcontent" :style="{float : (index%2 ==0) ? 'right' : 'left',textAlign:(index%2 ==0) ? 'right' : 'left'}">{{item.news_desc_text}}</div>
                        </div>
                    </el-col>
                    <el-col :span="12" :xs="24">
                        <el-image style="width:550px;cursor: pointer;" fit="contain" :src="item.news_img"></el-image>
                    </el-col>
                </div>
                <div v-if="index%2 !=0" @click="nav('news_info/'+item.news_id)">
                    <el-col :span="12" :xs="24">
                        <div style="float:right">
                            <el-image style="width:550px;cursor: pointer;" fit="contain" :src="item.news_img"></el-image>
                        </div>
                    </el-col>
                    <el-col :span="12" :xs="24">
                        <div class="date" style="margin-left:40px;margin-top:40px" :style="{float : (index%2 ==0) ? 'right' : 'left'}">
                            <div class="datey">{{item.y}}</div>
                            <div class="datem">{{item.m}}</div>
                            <div class="dated">{{item.d}}</div>
                            <div class="dateth">th</div>
                        </div>
                        <div style="margin-left:40px">
                            <div class="ftitle" :style="{float : (index%2 ==0) ? 'right' : 'left',textAlign:(index%2 ==0) ? 'right' : 'left'}">{{item.news_title}}</div>
                            <div class="fcontent" :style="{float : (index%2 ==0) ? 'right' : 'left',textAlign:(index%2 ==0) ? 'right' : 'left'}">{{item.news_desc_text}}</div>
                        </div>
                    </el-col>
                </div>
            </el-row>
            <el-pagination
                v-show="total>6"
                style="margin-top:3%;text-align:center"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="newsParams.page"
                :page-sizes="[7, 14, 35, 42]"
                :page-size="newsParams.limit"
                layout="total, prev, pager, next"
                :pager-count="7"
                :total="total">
            </el-pagination>
        </div>
    </div>
</template>
<script>
import { newsList} from "@/api/api"; 
export default {
    data(){
        return{
            newsParams:{
                page:1,
                limit:6
            },
            total:0,
            list:[],
            title:''
        }
    },
    beforeCreate() {
        document.querySelector('body').setAttribute('style', 'background:#ffffff')
    },
    // 在销毁前，将body的style属性删除
    deactivated() {
        document.querySelector('body').removeAttribute('style')
    },
    metaInfo () {
        return {
        title: this.title,
        meta: [
            {
                name: 'keyWords',
                content: this.$t('seo.indexkeywords')
            },
            {
                name: 'description',
                content: this.$t('seo.indexdescription')
            }
        ]
        }
    },
    created(){
        this.title = this.$t('layout.news')+'-'+this.$t('seo.indextitle')
        let page = this.$route.query.page
        if(page != undefined){
            this.newsParams.page = page-0
        }
        this.getList()
    },
    methods:{
        async getList(){
            const {data:res} = await newsList(this.newsParams)
            res.list.forEach((item)=>{
                item.news_img = process.env.VUE_APP_BASE_API+item.news_img
            })
            this.list = res.list
            this.total = res.total
            history.pushState(null, null, "/news?page="+this.newsParams.page)
        },
        handleSizeChange(value){
            this.newsParams.limit = value
            this.getList()
        },
        handleCurrentChange(value){
            this.newsParams.page = value
            this.getList()
        },
        nav(url){
            this.$router.push('/'+url,'_blank')
        }
    }
}
</script>
<style lang="scss" scoped>
    .appmain{
        width: 70%;
        margin-top: 2.5%;
    }
    @media screen and (max-width: 480px) {
        .appmain{
            width: 100%;
        }
    }
    .date{
        cursor: pointer;
        padding-top: 5%;
        display: flex;
        font-family: OPPOSans-H;
        .datey{
            font-size: 90px;
            font-weight: bold;
            color: #0052D9;
            opacity: 0.5;
        }
        .datem{
            font-size: 30px;
            font-weight: bold;
            margin-left: 0px;
        }
        .dated{
            font-size: 60px;
            margin-top: 8%;
            font-weight: bold;
            margin-left: 0px;
           
        }
        .dateth{
            font-size: 30px;
            margin-top: 13.5%;
            font-weight: bold;
            margin-left: 5px;
        }
    }
    .ftitle{
        width: 530px;
        cursor: pointer;
        font-size: 20px;
        font-weight: bold;
        margin-top: 10px;
        font-family: SourceHanSansCN-Bold;
        color: #0052D9;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }
    .fcontent{
        cursor: pointer;
        color: #333333;
        font-size: 16px;
        margin-top: 2%;
        width: 500px;
        font-family: SourceHanSansCN-Regular;
        font-weight: 400;
        color: #333333;
         overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
</style>